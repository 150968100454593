import React, { useState, useRef, useEffect } from 'react';
import ContentEditableDiv from '../ContentEditableDiv/ContentEditableDiv';
import { IBookChapter } from '../types';
import { ReactComponent as ThreeDots } from '../../../Assets/threeDots.svg';
import { ReactComponent as ChapterDelete } from '../../../Assets/chapterDelete.svg';
import { ReactComponent as ChapterAdd } from '../../../Assets/chapterAdd.svg';

import { ReactComponent as ChapterDragAndDrop } from '../../../Assets/chapterDragAndDrop.svg';

import s from './ChapterItemEditor.module.scss';
import DropdownBox from 'Components/Common/dropdownBox/DropdownBox';
import clsx from 'clsx';
import Spinner from 'Components/Common/Spinner/Spinner';
import { rxActiveChapterIndex } from 'rx/rxState';

interface IProps {
  chapter: IBookChapter;
  index: number;
  onChapterTitleEditedProps: (value: string, index: number) => void;
  activeChapter: number;
  setActiveChapter: (index: number) => void;
  deleteChapter: (index: number) => void;
  onChapterAdd: (indexBefore: number, action: 'add' | 'insert') => void;
  loadingAddChapter: boolean;
  isGeneratedAllBookEnd: boolean;
  indexGenarateChapterText?: number;
  isBookTitleActive: boolean;
  setIsBookTitleActive: (arg: boolean) => void;
}

function ChapterItemEditor({
  chapter,
  index,
  onChapterTitleEditedProps,
  activeChapter,
  setActiveChapter,
  deleteChapter,
  onChapterAdd,
  loadingAddChapter,
  isGeneratedAllBookEnd,
  indexGenarateChapterText,
  isBookTitleActive,
  setIsBookTitleActive,
}: IProps) {
  const [chapterTitleInput, setChapterTitleInput] = useState<string>(
    chapter.title
  );
  const [isDotsMenuOpen, setIsDotsMenuOpen] = useState<boolean>(false);
  const refDotsMenu = useRef(null);

    useEffect(()=>{
      setChapterTitleInput(chapter.title)
    },[chapter])
    
  const onChapterTitleEdited = (value: string) => {
    onChapterTitleEditedProps(value, index);
  };

  const onChapterClick = () => {
    if (isGeneratedAllBookEnd) {
      setActiveChapter(index);
      setIsBookTitleActive(false);
      rxActiveChapterIndex.next(index + 1);
    }
  };

  return (
    <div
      className={clsx(s.chapterItemWrapperEditVersion, {
        [s.chapterItemWrapperEditVersionActive]:
          index === activeChapter && !isBookTitleActive,
      })}
    >
      <div
        className={clsx(s.chapterItemEditVersion, {
          [s.chapterItemActiveEditVersion]:
            index === activeChapter && !isBookTitleActive,
        })}
        onClick={() => onChapterClick()}
      >
        <div
          className={clsx(
            s.chapterIndexCircle,
            s.chapterIndexCircleEditVersion,
            {
              [s.chapterIndexCircleNoneDisabled]:
                !isGeneratedAllBookEnd &&
                indexGenarateChapterText &&
                indexGenarateChapterText >= index,
              [s.chapterIndexCircleGenerated]:
                !isGeneratedAllBookEnd && indexGenarateChapterText === index,
              [s.chapterIndexCircleGeneratedEnd]: isGeneratedAllBookEnd,
            }
          )}
        >
          {!isGeneratedAllBookEnd && indexGenarateChapterText === index ? (
            <Spinner size={25} />
          ) : (
            index + 1
          )}
        </div>
        <div className={s.chapterTitleInputEditVersion}>
          <ContentEditableDiv
            name={chapterTitleInput}
            fontSize={14}
            lineHeight={20}
            fontWeight={500}
            width={180}
            height={40}
            onChange={!isGeneratedAllBookEnd ? () => {} : setChapterTitleInput}
            onEdit={!isGeneratedAllBookEnd ? () => {} : onChapterTitleEdited}
            onClick={() => onChapterClick()}
            isMultiline={true}
          />
        </div>
        <ChapterDragAndDrop className={s.chapterDragAndDropSvgEditVersion} />
        <ThreeDots
          className={s.threeDotsSvgEditVersion}
          ref={refDotsMenu}
          onClick={() => setIsDotsMenuOpen(true)}
        />
        <DropdownBox
          isOpen={isDotsMenuOpen}
          onClose={() => setIsDotsMenuOpen(false)}
          pointerEvents={true}
          componentRef={refDotsMenu}
          height={70}
        >
          <div className={s.dotsMenuBlock}>
            {/* <>
                <div
                  className={
                    loadingAddChapter || !isGeneratedAllBookEnd
                      ? s.cursorDisabled
                      : ''
                  }
                  onClick={
                    loadingAddChapter || !isGeneratedAllBookEnd
                      ? () => {}
                      : () => {
                          onChapterAdd(index, 'insert');
                          setIsDotsMenuOpen(false);
                        }
                  }
                >
                  <ChapterAdd /> Add New Above
                </div>
                <div
                  className={
                    loadingAddChapter || !isGeneratedAllBookEnd
                      ? s.cursorDisabled
                      : ''
                  }
                  onClick={
                    loadingAddChapter || !isGeneratedAllBookEnd
                      ? () => {}
                      : () => {
                          onChapterAdd(index + 1, 'insert');
                          setIsDotsMenuOpen(false);
                        }
                  }
                >
                  <ChapterAdd /> Add New Below
                </div>
              </> */}

            <div
              className={
                loadingAddChapter || !isGeneratedAllBookEnd
                  ? s.cursorDisabled
                  : ''
              }
              onClick={
                loadingAddChapter || !isGeneratedAllBookEnd
                  ? () => {}
                  : () => {
                      deleteChapter(index);
                      setIsDotsMenuOpen(false);
                    }
              }
            >
              <ChapterDelete /> Delete
            </div>
          </div>
        </DropdownBox>
      </div>
    </div>
  );
}


export default ChapterItemEditor;
