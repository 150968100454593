import Spinner from 'Components/Common/Spinner/Spinner';
import React, { useState, useRef, useEffect } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import ContentEditableDiv from '../ContentEditableDiv/ContentEditableDiv';
import { IBookChapter } from '../types';
import s from './BookInfoBlockEditor.module.scss';
import { ReactComponent as AddNewChapter } from '../../../Assets/addNewChapter.svg';
import clsx from 'clsx';
import { rxActiveChapterIndex } from 'rx/rxState';
import ChapterItemEditor from '../ChapterItem/ChapterItemEditor';

interface IProps {
  bookTitle: string;
  onBookTitleEdited: (value: string) => void;
  onChapterTitleEdited: (value: string, index: number) => void;
  chapters: IBookChapter[];
  loadingChapter: boolean;
  loadingAddChapter: boolean;
  deleteChapter: (index: number) => void;
  onChapterAdd: (indexBefore: number, action: 'add' | 'insert') => void;
  reorderChapter: (dragIndex: number, dropIndex: number) => void;
  endReorderChapter: () => void;
  isGeneratedAllBookEnd: boolean;
  indexGenarateChapterText?: number;
}

function BookInfoBlockEditor({
  bookTitle,
  onBookTitleEdited,
  chapters,
  loadingChapter,
  onChapterTitleEdited,
  deleteChapter,
  onChapterAdd,
  reorderChapter,
  endReorderChapter,
  loadingAddChapter,
  isGeneratedAllBookEnd,
  indexGenarateChapterText,
}: IProps) {
  const [bookTitleInput, setBookTitleInput] = useState<string>(bookTitle);
  const [activeChapter, setActiveChapter] = useState<number>(0);
  const [isBookTitleActive, setIsBookTitleActive] = useState<boolean>(false);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [lastSortingTimestamp, setLastSortingTimestamp] = useState<
    number | null
  >(null);

  useEffect(() => {
    setBookTitleInput(bookTitle);
  }, [bookTitle]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      const currentTime = Date.now();
      if (lastSortingTimestamp && currentTime - lastSortingTimestamp > 2000) {
        endReorderChapter();
      }
    }, 2000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [lastSortingTimestamp]);

  const onTitleInputClick = () => {
    setIsBookTitleActive(true);
    rxActiveChapterIndex.next(0);
  };

  const handleChapterSorting = (res: DropResult) => {
    const { destination, source, draggableId } = res;

    if (!destination) {
      return;
    }
    if (isGeneratedAllBookEnd) {
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }
      setLastSortingTimestamp(Date.now());
      reorderChapter(source.index, destination.index);
    }
  };
  return (
    <div>
      <div
        className={clsx(s.bookInfoEditNameInputEditVersion, {
          [s.bookInfoEditNameInputEditVersionActive]: isBookTitleActive,
        })}
      >
        <ContentEditableDiv
          name={bookTitleInput}
          fontSize={14}
          lineHeight={20}
          fontWeight={500}
          width={300}
          height={50}
          onChange={!isGeneratedAllBookEnd ? () => {} : setBookTitleInput}
          onEdit={!isGeneratedAllBookEnd ? () => {} : onBookTitleEdited}
          onClick={() => onTitleInputClick()}
        />
      </div>
      <DragDropContext onDragEnd={handleChapterSorting}>
        <Droppable droppableId="chapters">
          {(provided) => (
            <div
              className={s.chaptersBlockEditVersion}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {chapters.map((chapter: IBookChapter, index) => (
                <ChapterItemEditor
                  index={index}
                  chapter={chapter}
                  onChapterTitleEditedProps={onChapterTitleEdited}
                  activeChapter={activeChapter}
                  setActiveChapter={setActiveChapter}
                  deleteChapter={deleteChapter}
                  onChapterAdd={onChapterAdd}
                  loadingAddChapter={loadingAddChapter}
                  isGeneratedAllBookEnd={isGeneratedAllBookEnd}
                  indexGenarateChapterText={indexGenarateChapterText}
                  isBookTitleActive={isBookTitleActive}
                  setIsBookTitleActive={setIsBookTitleActive}
                />
              ))}
              {/* {!loadingChapter &&  (
                <div
                  className={s.addNewChapter}
                  onClick={() => onChapterAdd(chapters.length, 'add')}
                >
                  {loadingAddChapter || !isGeneratedAllBookEnd ? (
                    !isGeneratedAllBookEnd ? null : (
                      <Spinner size={25} />
                    )
                  ) : (
                    <AddNewChapter />
                  )}
                </div>
              )} */}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default BookInfoBlockEditor;
